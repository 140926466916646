import { Box, Button, IconButton } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createCommonAPICall } from "../../utility/helpers";
import createRequestService from "../../services/create-request-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import { AppRoutings } from "../../utility/enums/app-routings";
import { CancelAgreementModal, SkipVideoMessageModal } from "../../components";
import tokenManager from "../../utility/auth-guards/token-manager";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

const ReviewAgreement: React.FC = () => {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // page level varaiables
  const navigate = useNavigate();

  // get query parameters
  const { data } = useParams();

  // use states
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoSrcMobile, setVideoSrcMobile] = useState("");
  const [requestId, setRequestId] = useState(0);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [patientFullName, setPatientFullName] = useState("");
  const [isReturningPatient, setIsReturningPatient] = useState(false);
  const [isSkipeMessageModalOpen, setIsSkipMessageModalOpen] = useState(false);

  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    window.innerWidth
  );
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  // use ref
  const videoElement = useRef<HTMLVideoElement>(null);

  //handle events
  const videoEnded = () => {
    setIsVideoEnded(true);
  };

  const handleCancelAgreementModalOpen = () => {
    setCancelModalOpen(true);
  };

  const handleCancelAgreementModalClose = () => {
    setCancelModalOpen(false);
  };

  const checkLinkExpiry = async () => {
    tokenManager.clearCookiesAndLocalStorage();
    console.log(data);
    const res = await createCommonAPICall({
      requestBody: { data: data },
      apiService: createRequestService.checkAgreementLinkValidity,
      showErrorMessage: true,
      showSuccessMessage: false,
      setSuccessErrorContext,
    });

    if (res && res.isSuccessfull) {
      console.log(res);
      setVideoSrc(res.data.filepath);
      setVideoSrcMobile(res.data.filepathMobile);
      setRequestId(res.data.requestId);
      setPatientFullName(res.data.patientFullName);
      setIsReturningPatient(res.data.isReturningPatient);
    } else {
      navigate(AppRoutings.Root);
    }
  };

  const handleClickAgree = async () => {
    console.log("I agree");
    const requestBody = {
      requestId: requestId,
      isAgreed: true,
    };
    const data = await createCommonAPICall({
      requestBody,
      apiService: createRequestService.acceptOrRejectAgreement,
      showErrorMessage: true,
      showSuccessMessage: true,
      setSuccessErrorContext,
    });

    navigate(AppRoutings.Root, {
      state: {
        isLogin: true,
      },
    });
  };

  const handleClickCancel = async (notes: string) => {
    console.log(notes);
    const requestBody = {
      requestId: requestId,
      isAgreed: false,
      notes: notes,
    };
    const data = await createCommonAPICall({
      requestBody,
      apiService: createRequestService.acceptOrRejectAgreement,
      showErrorMessage: true,
      showSuccessMessage: true,
      setSuccessErrorContext,
    });

    navigate(AppRoutings.Root);
  };

  const handleClickPlay = () => {
    videoElement.current?.play();
    setIsVideoPlaying(true);
  };

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  }
  const handleVideoPause = () => {
    setIsVideoPlaying(false);
  }

  // use effect
  useEffect(() => {
    // check data validity
    if (data) checkLinkExpiry();

    window.addEventListener("resize", (event) => {
      setCurrentWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", (event) => {
        setCurrentWindowWidth(window.innerWidth);
      });
    };
  }, []);

  return (
    <Box>
      <main className="main-content">
        <Box className="request-container-box">
          {videoSrc != "" && currentWindowWidth > 767 && (
            <Box className="video-container" justifyContent={"center"}>
              <video
                id="video"
                ref={videoElement}
                controls
                controlsList="nodownload nofullscreen noplaybackrate"
                src={videoSrc}
                onEnded={videoEnded}
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
                onClick={(e) => {
                  if (e.currentTarget.paused) e.currentTarget.play();
                  else e.currentTarget.pause();
                }}
                onFocus={(e) => e.target.blur()}
                disablePictureInPicture
              ></video>
              {!isVideoPlaying && (
                <button id="playButton" onClick={handleClickPlay}>
                  <PlayCircleIcon />
                  <span>Play</span>
                </button>
              )}
            </Box>
          )}

          {videoSrcMobile != "" && currentWindowWidth <= 767 && (
            <Box className="video-container-mobile" justifyContent={"center"}>
              <video
                id="video"
                ref={videoElement}
                controls
                controlsList="nodownload nofullscreen noplaybackrate"
                src={videoSrcMobile}
                onEnded={videoEnded}
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
                onClick={(e) => {
                  if (e.currentTarget.paused) e.currentTarget.play();
                  else e.currentTarget.pause();
                }}
                onFocus={(e) => e.target.blur()}
                disablePictureInPicture
              ></video>
              {!isVideoPlaying && (
                <button id="playButton" onClick={handleClickPlay}>
                  <PlayCircleIcon />
                  <span>Play</span>
                </button>
              )}
            </Box>
          )}

          <Box className="agreement-buttons">
            <Button
              // disabled={!isVideoEnded}
              className={`large-custom success-btn ${
                !isVideoEnded ? "Mui-disabled" : ""
              }`}
              variant="contained"
              color="success"
              size="large"
              onClick={() => {
                if (isVideoEnded) {
                  handleClickAgree();
                } else {
                  if (videoElement.current) videoElement.current.pause();
                  setIsSkipMessageModalOpen(true);
                }
              }}
              style={{ pointerEvents: "all" }}
            >
              I Agree
            </Button>
            <Button
              className="large-custom"
              variant="contained"
              color="error"
              size="large"
              onClick={handleCancelAgreementModalOpen}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </main>

      {/* Page modals */}
      <CancelAgreementModal
        cancelAgreementModalOpen={isCancelModalOpen}
        handleCancelAgreementModalClose={handleCancelAgreementModalClose}
        patientFullName={patientFullName}
        handleClickCancel={handleClickCancel}
      />
      <SkipVideoMessageModal
        isModalOpen={isSkipeMessageModalOpen}
        isReturningPatient={isReturningPatient}
        handleModalClose={() => setIsSkipMessageModalOpen(false)}
        handleSkipClick={handleClickAgree}
      />
    </Box>
  );
};

export default ReviewAgreement;
