export enum Constants {
  // Values -------
  DefaultTablePageSize = 20,
  AppTokenKey = "app_token",
  UserDetails = "user_details",
  MenuPermissions = "menu_permissions",
  DtySecretKey = "dty_secret",
  ChatCount = "chat_count",
  LocalAppEncryptkey = "123456789",
  // AdminId = 1,
  // HubUrl = "https://localhost:7080/chat-hub",
  // LocationHubUrl = "https://localhost:7080/location-hub",
  AdminId = 34,
  // HubUrl = "http://172.174.160.184:81/chat-hub",
  // LocationHubUrl = "http://172.174.160.184:81/location-hub",

  // New GCP Stage Api
	// HubUrl = "http://34.148.250.95:91/chat-hub",
	// LocationHubUrl = "http://34.148.250.95:91/location-hub",

  HubUrl = "https://api2.doctorstoyou.com/chat-hub",
  LocationHubUrl = "https://api2.doctorstoyou.com/location-hub",
  // GoogleKey = "",
  GoogleKey = "AIzaSyAfEZn4DREuddHRFBoGw4Q83DHL_YyHQfk",
  GeocodeKey = "AIzaSyC6oU-5BUHNiFf5Mdm8pAEf1-qBjQnJfzQ",
}

export enum AccountType {
  All = 0,
  Admin = 1,
  Physician = 2,
  Business = 3,
  HaloAdministrator = 4,
  Patient = 5,
  PatientAccount = 6,
  VIP = 7,
  Groups = 8,
}
